import { Button, ButtonBase, TextField } from '@mui/material';
import { motion } from 'framer-motion';
import { Fragment } from 'react';
import { PatternFormat } from 'react-number-format';
import formLocalizedHelperText from 'utils/formLocalizedHelperText';
import { fadeUp } from 'utils/motion';

const FormMultiplePhonesField = ({
  delay = 0,
  duration = 0.5,
  fieldName,
  childFieldName,
  formik,
  label,
  onKeyDown = () => {},
  readOnly = false,
  disabled = false,
  variant = 'outlined',
}) => {
  const getFieldValidation = (formikF, index) => {
    let a = formik[formikF]
      ? formik[formikF][fieldName]
        ? formik[formikF][fieldName][index]
          ? formik[formikF][fieldName][index][childFieldName]
          : false
        : false
      : false;
    return a;
  };

  const handleAddPhone = () => {
    if (formik.values[fieldName] && formik.values[fieldName].length < 5) {
      formik.setFieldValue(
        fieldName,
        [
          ...JSON.parse(JSON.stringify(formik.values[fieldName])),
          {
            [childFieldName]: '',
          },
        ],
        true
      );
    }
  };

  const handleRemovePhone = (phoneIndex) => {
    let newPhones = JSON.parse(JSON.stringify(formik.values[fieldName])).filter(
      (item, index) => index !== phoneIndex
    );
    formik.setFieldValue(fieldName, [...newPhones], true);
  };

  return (
    <Fragment>
      {formik &&
        formik.values &&
        formik.touched &&
        formik.errors &&
        formik.values[fieldName] &&
        formik.values[fieldName].map((phone, index) => (
          <motion.div
            key={`client-phone-${index}`}
            variants={fadeUp(30, 'tween', delay, duration)}
            initial='hidden'
            animate='show'
            viewport={{ once: true, amount: 0.25 }}
            className='flex items-center'
          >
            <PatternFormat
              id={`${childFieldName}-phone-${index}`}
              name={`${childFieldName}-phone-${index}`}
              label={label}
              value={phone[childFieldName]}
              delay={delay}
              onChange={(event) => {
                formik.setFieldValue(
                  `${fieldName}.${index}.${childFieldName}`,
                  event.target.value,
                  true
                );
              }}
              error={
                getFieldValidation('touched', index) &&
                Boolean(getFieldValidation('errors', index))
              }
              helperText={
                getFieldValidation('touched', index) &&
                formLocalizedHelperText(getFieldValidation('errors', index))
              }
              color='formColor'
              variant={variant}
              fullWidth
              customInput={TextField}
              InputProps={{
                readOnly: readOnly,
                disabled: disabled,
              }}
              onKeyDown={onKeyDown}
              format='+998 ## ###-##-##'
            />
            {index == 0 ? (
              <Button
                variant='action'
                color='secondary'
                className='!ml-2'
                onClick={() => handleAddPhone()}
                disabled={formik.values[fieldName].length == 5}
              >
                <i className='bi bi-plus-circle' />
              </Button>
            ) : (
              <Button
                variant='action'
                color='error'
                className='!ml-2'
                onClick={() => handleRemovePhone(index)}
              >
                <i className='bi bi-trash3' />
              </Button>
            )}
          </motion.div>
        ))}
    </Fragment>
  );
};

export default FormMultiplePhonesField;
