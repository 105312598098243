import useAxiosPrivate from "hooks/useAxiosPrivate"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import DateSelectBox from "./DateSelectBox"
import moment from "moment"
import { NumericFormat, numericFormatter } from "react-number-format"
import {
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton
} from "@mui/material"
import BarChart from "components/ui/charts/BarChart"
import { colors } from "shared/colors"

const IncomeByResidentialComplex = () => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	// const [hasError, setHasError] = useState(false)
	const [totalStatisticsData, setTotalStatisticsData] = useState([])
	const [openModal, setOpenModal] = useState(false)
	const [selectedObject, setSelectedObject] = useState({})
	const [startAndFinishDate, setStartAndFinishDate] = useState({
		startDate: moment()
			.set("years", 2010)
			.startOf("years")
			.format("YYYY-MM-DD"),
		finishDate: moment().format("YYYY-MM-DD")
	})

	const { isLoading, isFetching, isError } = useQuery({
		queryKey: ["totalStatistics", startAndFinishDate],
		queryFn: async function () {
			const response = await axiosPrivate.get(
				`/analytic/object/objects?from=${startAndFinishDate.startDate}&till=${startAndFinishDate.finishDate}`
			)
			return response.data.data
		},
		onSuccess: (data) => {
			if (data && data.length > 0) {
				setTotalStatisticsData(data)
			}
		},
		// enabled: !hasError,
		// onError: (error) => {
		// 	setHasError(true)
		// },
		retry: false
	})

	const handleClickBar = (event, elements, chart) => {
		if (elements && elements.length > 0) {
			setSelectedObject(totalStatisticsData[elements[0].index])
			setOpenModal(true)
		}
	}

	return (
		<div className="residential-complex-item income-by-residential-complex">
			<div className="residential-complex-item-header">
				<div className="residential-complex-item-title income-by-residential-complex-title">
					Turar-joy majmualari bo'yicha
				</div>
				<div className="w-[150px]">
					<DateSelectBox
						setStartAndFinishDate={setStartAndFinishDate}
						disabled={isLoading || isFetching}
					/>
				</div>
			</div>

			<div className="income-by-residential-body">
				{isError ? (
					<div className="no-data-found-wrapper h-full">
						<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
						{t("common.global.noDataFound")}
					</div>
				) : totalStatisticsData.length > 0 ? (
					<div className="income-by-residential-chart">
						<BarChart
							chartLabels={[...totalStatisticsData].map((item) => item?.name)}
							chartDatasets={[
								{
									data: [...totalStatisticsData].map((item) => item?.sum),
									borderRadius: 4,
									borderWidth: 2,
									borderColor: colors.baseColorLight,
									backgroundColor: colors.baseColorOutline,
									hoverBackgroundColor: colors.baseColorLight,
									tension: 0.3
								}
							]}
							optionPlugins={{
								tooltip: {
									usePointStyle: true,
									yAlign: "bottom",
									callbacks: {
										label: function (tooltipItem) {
											return ` ${numericFormatter(tooltipItem.raw.toString(), {
												decimalScale: 3,
												thousandSeparator: " ",
												allowNegative: false,
												suffix: " UZS"
											})}`
										},
										labelPointStyle: function () {
											return {
												pointStyle: "rectRounded",
												rotation: 0
											}
										},
										title: () => null
									}
								}
							}}
							options={{
								scales: {
									y: {
										ticks: {
											callback: function (value) {
												let isDecimalScale =
													parseFloat(value) / Math.pow(10, 9) >= 1
												return Math.floor(value) > 1
													? numericFormatter(
															isDecimalScale
																? (
																		parseFloat(value) / Math.pow(10, 9)
																  ).toString()
																: (
																		parseFloat(value) / Math.pow(10, 6)
																  ).toString(),
															{
																decimalScale: 3,
																thousandSeparator: " ",
																allowNegative: false,
																suffix: isDecimalScale
																	? " mlrd UZS"
																	: " mln UZS"
															}
													  )
													: value
											}
										}
									}
								},
								onClick: handleClickBar,
								onHover: (event, chartElement) => {
									event.native.target.style.cursor = chartElement[0]
										? "pointer"
										: "default"
								}
							}}
						/>
					</div>
				) : (
					<div className="h-[260px] flex items-center justify-center">
						<CircularProgress size={30} color="inherit" />
					</div>
				)}
			</div>

			<IncomeDetailsByResidentialComplex
				open={openModal}
				setOpen={setOpenModal}
				object={selectedObject}
				startAndFinishDate={startAndFinishDate}
			/>
		</div>
	)
}

const IncomeDetailsByResidentialComplex = ({
	open,
	setOpen,
	object = {},
	startAndFinishDate = {}
}) => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [totalDetails, setTotalDetails] = useState({})

	const { data, isLoading, isFetching } = useQuery({
		queryKey: ["incomeDetailsByResidentialComplex", object],
		queryFn: async function () {
			const response = await axiosPrivate.get(
				`/analytic/object/objectview/${object?.id}?from=${startAndFinishDate.startDate}&till=${startAndFinishDate.finishDate}`
			)
			return response.data.data
		},
		onSuccess: (data) => {
			if (data && data.length > 0) {
				let newTotalDetails = {
					insale: 0,
					sold: 0,
					paid: 0,
					waiting: 0
				}
				data.forEach((item) => {
					newTotalDetails.insale += item?.insale
					newTotalDetails.sold += item?.sold
					newTotalDetails.paid += item?.paid
					newTotalDetails.waiting += item?.waiting
				})
				setTotalDetails(newTotalDetails)
			}
		},
		enabled: !!object?.id,
		retry: false
	})

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="income-details-by-residential-complex"
			aria-describedby="income-details-by-residential-complex-modal"
			maxWidth="sm"
			fullWidth
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="income-details-by-residential-complex">
				<span>{object?.name}</span>
				<div className="close-btn-wrapper">
					<IconButton
						variant="onlyIcon"
						color="primary"
						onClick={() => setOpen(false)}
					>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<div className="income-details-by-residential-complex-modal-wrapper">
					{isLoading || isFetching ? (
						<div className="progress-loader">
							<CircularProgress size={30} color="inherit" />
						</div>
					) : data && data.length > 0 ? (
						<div className="w-full">
							<div className="total-details">
								<div className="block-name">
									<i className="bi bi-buildings text-xl mr-1" />
									{object?.name} turar-joy majmuasi
								</div>
								<div className="in-sale-price">
									<div>Sotuvda</div>
									<NumericFormat
										value={totalDetails?.insale}
										displayType={"text"}
										allowNegative={false}
										thousandSeparator={" "}
										decimalScale={3}
										className="bg-transparent"
										suffix=" UZS"
									/>
								</div>
								<div className="sold-price">
									<div>Sotilgan</div>
									<NumericFormat
										value={totalDetails?.sold}
										displayType={"text"}
										allowNegative={false}
										thousandSeparator={" "}
										decimalScale={3}
										className="bg-transparent"
										suffix=" UZS"
									/>
								</div>
								<div className="paid-price">
									<div>Undirilgan mablag'</div>
									<NumericFormat
										value={totalDetails?.paid}
										displayType={"text"}
										allowNegative={false}
										thousandSeparator={" "}
										decimalScale={3}
										className="bg-transparent"
										suffix=" UZS"
									/>
								</div>
								<div className="waiting-price">
									<div>Kutilayotgan mablag'</div>
									<NumericFormat
										value={totalDetails?.waiting}
										displayType={"text"}
										allowNegative={false}
										thousandSeparator={" "}
										decimalScale={3}
										className="bg-transparent"
										suffix=" UZS"
									/>
								</div>
							</div>
							{data.map((item, index) => (
								<div
									className="income-details-by-block-item"
									key={`income-details-by-block-${index}`}
								>
									<div className="block-name">
										<i className="bi bi-building text-xl mr-1" />
										{item?.name}
									</div>
									<div className="income-details-body">
										<div className="in-sale-price">
											<div>Sotuvda</div>
											<NumericFormat
												value={item?.insale}
												displayType={"text"}
												allowNegative={false}
												thousandSeparator={" "}
												decimalScale={3}
												className="bg-transparent"
												suffix=" UZS"
											/>
										</div>
										<div className="sold-price">
											<div>Sotilgan</div>
											<NumericFormat
												value={item?.sold}
												displayType={"text"}
												allowNegative={false}
												thousandSeparator={" "}
												decimalScale={3}
												className="bg-transparent"
												suffix=" UZS"
											/>
										</div>
										<div className="paid-price">
											<div>Undirilgan mablag'</div>
											<NumericFormat
												value={item?.paid}
												displayType={"text"}
												allowNegative={false}
												thousandSeparator={" "}
												decimalScale={3}
												className="bg-transparent"
												suffix=" UZS"
											/>
										</div>
										<div className="waiting-price">
											<div>Kutilayotgan mablag'</div>
											<NumericFormat
												value={item?.waiting}
												displayType={"text"}
												allowNegative={false}
												thousandSeparator={" "}
												decimalScale={3}
												className="bg-transparent"
												suffix=" UZS"
											/>
										</div>
									</div>
								</div>
							))}
						</div>
					) : (
						<div className="no-data-found-wrapper w-full h-[100px]">
							<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
							{t("common.global.noDataFound")}
						</div>
					)}
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default IncomeByResidentialComplex
