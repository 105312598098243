const HOME_TYPE = {
	ACTIVE: {
		code: 1,
		label: "common.typeList.homeType.active"
	},
	TIME: {
		code: 2,
		label: "common.typeList.homeType.time"
	},
	ORDERED: {
		code: 3,
		label: "common.typeList.homeType.ordered"
	},
	DISABLED: {
		code: 4,
		label: "common.typeList.homeType.disabled"
	}
}

export default HOME_TYPE
