import {
  Button,
  CircularProgress,
  LinearProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import BarChart from 'components/ui/charts/BarChart';
import SimpleToggleButtonGroup from 'components/ui/simple-fields/toggle-button-group/SimpleToggleButtonGroup';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import 'moment/locale/uz-latn';
import 'moment/locale/ru';
import 'moment/locale/uz';
import { colors } from 'shared/colors';
import { NumericFormat, numericFormatter } from 'react-number-format';
import ObjectSelectBox from 'components/boss/ObjectSelectBox';

const TabOne = () => {
  const { t, i18n } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const [page, setPage] = useState(1);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [paymentsData, setPaymentsData] = useState([]);
  const [selectedObjectId, setSelectedObjectId] = useState('ALL');
  const [dateType, setDateType] = useState('DAILY');
  const [total, setTotal] = useState(0);
  const [dateTypes, setDateTypes] = useState([
    {
      code: 'DAILY',
      label: 'Kunlik',
      parserCode: 'months',
      isFourYears: false,
    },
    { code: 'MONTH', label: 'Oylik', parserCode: 'years', isFourYears: false },
    { code: 'YEAR', label: 'Yillik', parserCode: 'years', isFourYears: true },
  ]);
  const [startAndFinishDates, setStartAndFinishDates] = useState({
    startDate: moment().startOf('months').format('YYYY-MM-DD'),
    finishDate: moment().endOf('months').format('YYYY-MM-DD'),
  });
  const localeMoment = moment;

  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: ['analyticPayment', startAndFinishDates, selectedObjectId],
    queryFn: async function () {
      const response = await axiosPrivate.get(
        `/analytic/payment/index?type=${dateType}&start=${
          startAndFinishDates.startDate
        }&finish=${startAndFinishDates.finishDate}${
          selectedObjectId !== 'ALL' ? `&objects[0]=${selectedObjectId}` : ''
        }`
      );
      return response.data.data;
    },
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setPaymentsData([...data]);
        setTotal([...data].reduce((acc, curr) => acc + curr?.sum, 0));
        setPage(1);
        setPaymentTypes(
          data[0].hasOwnProperty('types') ? Object.keys(data[0]?.types) : []
        );
      }
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const handleDateType = (value) => {
    setDateType(value);
    let currDateType = dateTypes.find((item) => item.code == value);

    if (currDateType.isFourYears) {
      setStartAndFinishDates({
        startDate: moment()
          .subtract(4, currDateType.parserCode)
          .startOf(currDateType.parserCode)
          .format('YYYY-MM-DD'),
        finishDate: moment()
          .endOf(currDateType.parserCode)
          .format('YYYY-MM-DD'),
      });
    } else {
      setStartAndFinishDates({
        startDate: moment()
          .startOf(currDateType.parserCode)
          .format('YYYY-MM-DD'),
        finishDate: moment()
          .endOf(currDateType.parserCode)
          .format('YYYY-MM-DD'),
      });
    }
  };

  const handlePrevMonth = () => {
    let currDateType = dateTypes.find((item) => item.code == dateType);

    let subtractNumber = currDateType.isFourYears ? 4 : 1;

    let newStartDate = moment(startAndFinishDates.startDate, 'YYYY-MM-DD')
      .subtract(subtractNumber, currDateType.parserCode)
      .startOf(currDateType.parserCode)
      .format('YYYY-MM-DD');

    let newFinishDate = moment(startAndFinishDates.finishDate, 'YYYY-MM-DD')
      .subtract(subtractNumber, currDateType.parserCode)
      .endOf(currDateType.parserCode)
      .format('YYYY-MM-DD');

    setStartAndFinishDates({
      startDate: newStartDate,
      finishDate: newFinishDate,
    });
  };

  const handleNextMonth = () => {
    let currDateType = dateTypes.find((item) => item.code == dateType);

    let addNumber = currDateType.isFourYears ? 4 : 1;

    let newStartDate = moment(startAndFinishDates.startDate, 'YYYY-MM-DD')
      .add(addNumber, currDateType.parserCode)
      .startOf(currDateType.parserCode)
      .format('YYYY-MM-DD');

    let newFinishDate = moment(startAndFinishDates.finishDate, 'YYYY-MM-DD')
      .add(addNumber, currDateType.parserCode)
      .endOf(currDateType.parserCode)
      .format('YYYY-MM-DD');

    setStartAndFinishDates({
      startDate: newStartDate,
      finishDate: newFinishDate,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    localeMoment.locale(i18n.language == 'uz' ? 'uz-latn' : 'uz');
  }, [i18n]);

  return (
    <div className='boss-payment-tab-wrapper tab-one'>
      <div className='payment-tab-top-actions-wrapper'>
        <div className='payment-tab-top-actions-body'>
          <SimpleToggleButtonGroup
            delay={0}
            duration={0}
            value={dateType}
            changeFn={handleDateType}
            options={dateTypes}
            size='small'
            fullWidth={true}
          />
          <div className='w-full ml-4'>
            <ObjectSelectBox
              value={selectedObjectId}
              setValue={setSelectedObjectId}
            />
          </div>
        </div>
      </div>
      <div className='payment-tab-chart'>
        <div className='chart-header'>
          <Button
            variant='action'
            color='secondary'
            onClick={() => handlePrevMonth()}
            disabled={isLoading || isFetching}
          >
            <i className='bi bi-chevron-left' />
          </Button>
          <div className='chart-title'>
            {dateType == 'DAILY' &&
              `${moment(startAndFinishDates.startDate, 'YYYY-MM-DD').format(
                'MMMM'
              )}, `}
            {dateType !== 'YEAR' &&
              moment(startAndFinishDates.startDate, 'YYYY-MM-DD').year()}
            {dateType == 'YEAR' &&
              `${moment(
                startAndFinishDates.startDate,
                'YYYY-MM-DD'
              ).year()} - ${moment(
                startAndFinishDates.finishDate,
                'YYYY-MM-DD'
              ).year()}`}
            {isLoading || isFetching ? (
              <div className='flex mt-1.5'>
                <CircularProgress size={15} color='inherit' />
              </div>
            ) : (
              <NumericFormat
                value={total}
                displayType={'text'}
                allowNegative={false}
                thousandSeparator={' '}
                decimalScale={3}
                className='chart-total-sum bg-transparent'
                suffix=' UZS'
              />
            )}
          </div>
          <Button
            variant='action'
            color='secondary'
            onClick={() => handleNextMonth()}
            disabled={isLoading || isFetching}
          >
            <i className='bi bi-chevron-right' />
          </Button>
        </div>
        {isError ? (
          <div>
            <span className='no-data-found-wrapper'>
              <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
              {t('common.global.noDataFound')}
            </span>
          </div>
        ) : paymentsData.length > 0 ? (
          <div className='h-[300px]'>
            <BarChart
              chartLabels={
                dateType == 'DAILY'
                  ? [...paymentsData].map((item) =>
                      localeMoment(item.date, 'YYYY-MM-DD').format('DD')
                    )
                  : dateType == 'MONTH'
                  ? [...paymentsData].map((item) =>
                      localeMoment(item.date, 'YYYY-MM-DD').format('MMMM')
                    )
                  : [...paymentsData].map((item) =>
                      localeMoment(item.year, 'YYYY-MM-DD').format('YYYY')
                    )
              }
              chartDatasets={[
                {
                  data: [...paymentsData].map((item) => item.sum),
                  borderRadius: 4,
                  borderWidth: 2,
                  borderColor: colors.baseColorLight,
                  backgroundColor: colors.baseColorOutline,
                  hoverBackgroundColor: colors.baseColorLight,
                  tension: 0.3,
                },
              ]}
              optionPlugins={{
                tooltip: {
                  usePointStyle: true,
                  yAlign: 'bottom',
                  callbacks: {
                    label: function (tooltipItem) {
                      return ` ${numericFormatter(tooltipItem.raw.toString(), {
                        decimalScale: 3,
                        thousandSeparator: ' ',
                        allowNegative: false,
                        suffix: ' UZS',
                      })}`;
                    },
                    labelPointStyle: function () {
                      return {
                        pointStyle: 'rectRounded',
                        rotation: 0,
                      };
                    },
                    title: () => null,
                  },
                },
              }}
              options={{
                scales: {
                  y: {
                    ticks: {
                      callback: function (value) {
                        return Math.floor(value) > 1
                          ? numericFormatter(
                              (parseFloat(value) / Math.pow(10, 6)).toString(),
                              {
                                decimalScale: 3,
                                thousandSeparator: ' ',
                                allowNegative: false,
                                suffix: ' mln UZS',
                              }
                            )
                          : value;
                      },
                    },
                  },
                },
              }}
            />
          </div>
        ) : (
          <div className='h-[300px] flex items-center justify-center'>
            <CircularProgress size={30} color='inherit' />
          </div>
        )}
      </div>
      <div className='payment-tab-table'>
        <TableContainer className='flex-auto h-full'>
          <Table
            stickyHeader
            sx={{ minWidth: 750, height: 'max-content' }}
            aria-labelledby='tableTitle'
          >
            <TableHead>
              <TableRow>
                <TableCell>Sana</TableCell>
                {paymentTypes.map((item) => (
                  <TableCell key={`payment-type-${item}`}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            {isLoading || isFetching ? (
              <TableBody className='overflow-hidden'>
                <TableRow>
                  <TableCell colSpan={paymentTypes.length}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : data && data.length > 0 ? (
              <TableBody className='overflow-hidden'>
                {data.slice(10 * (page - 1), page * 10).map((row, rowIndex) => {
                  return (
                    <TableRow hover tabIndex={-1} key={'row-' + rowIndex}>
                      <TableCell>
                        {dateType == 'DAILY'
                          ? localeMoment(row.date, 'YYYY-MM-DD').format(
                              'DD MMMM'
                            )
                          : dateType == 'MONTH'
                          ? localeMoment(row.date, 'YYYY-MM-DD').format('MMMM')
                          : localeMoment(row.year, 'YYYY-MM-DD').format('YYYY')}
                        {}
                      </TableCell>
                      {paymentTypes.map((item) => (
                        <TableCell
                          key={`payment-${item}-item-${rowIndex}-value`}
                        >
                          <NumericFormat
                            value={row?.types[item]}
                            displayType={'text'}
                            allowNegative={false}
                            thousandSeparator={' '}
                            decimalScale={3}
                            className='bg-transparent'
                            suffix=' UZS'
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={paymentTypes.length}>
                    <span className='text-gray-400 flex items-center justify-center'>
                      <i className='bi bi-exclamation-octagon text-xl leading-4 mr-1' />{' '}
                      {t('common.global.noDataFound')}
                    </span>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {data && data.length > 0 && (
          <div className='p-3 mb-2 mt-4 flex items-center justify-center rounded-lg my-shadow-2'>
            <Pagination
              count={Math.ceil(data.length / 10) || 1}
              page={page}
              onChange={handleChangePage}
              variant='outlined'
              color='primary'
              showFirstButton
              showLastButton
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default TabOne;
