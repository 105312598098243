import { CircularProgress } from "@mui/material"
import DoughnutChart from "components/ui/charts/DoughnutChart"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"
import { useQuery } from "react-query"
import { colorSeparators } from "shared/colors"

const TotalStatistics = () => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const [totalSum, setTotalSum] = useState(0)

	const { data, isLoading, isFetching } = useQuery({
		queryKey: "totalStatistics",
		queryFn: async function () {
			const response = await axiosPrivate.get("/analytic/object/index")
			return response.data.data
		},
		onSuccess: (data) => {
			if (data && Object.keys(data).length > 0) {
				setTotalSum(
					Object.keys(data).reduce((acc, curr) => acc + data[curr], 0)
				)
			}
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	return (
		<div className="residential-complex-item total-statistics">
			<div className="residential-complex-item-header">
				<div className="residential-complex-item-title total-statistics-title">
					Umumiy statistika
				</div>
			</div>

			<div className="total-statistics-body">
				<div className="total-statistics-body-header">
					<div className="total-statistics-body-title">Umumiy uylar bahosi</div>
					{isLoading || isFetching ? (
						<div className="flex mt-1.5">
							<CircularProgress size={15} color="inherit" />
						</div>
					) : (
						<NumericFormat
							value={totalSum}
							displayType={"text"}
							allowNegative={false}
							thousandSeparator={" "}
							decimalScale={3}
							className="total-statistics-total-sum bg-transparent"
							suffix=" UZS"
						/>
					)}
				</div>

				{isLoading || isFetching ? (
					<div className="flex w-full h-[220px] items-center justify-center">
						<CircularProgress size={30} color="inherit" />
					</div>
				) : data && Object.keys(data).length > 0 ? (
					<div className="total-statistics-chart">
						<DoughnutChart
							chartLabels={["Undirilgan mablag'", "Sotuvda", "Kutilmoqda"]}
							chartDatasets={[
								{
									data: [data?.paid, data?.insale, data?.waiting],
									backgroundColor: [
										`rgba(${colorSeparators.successColor}, 0.8)`,
										`rgba(${colorSeparators.warningColor}, 0.8)`,
										`rgba(${colorSeparators.baseColorLight}, 0.8)`
									],
									borderWidth: 2,
									hoverOffset: 15,
									hoverBorderWidth: 0,
									borderRadius: 4
								}
							]}
							optionPlugins={{
								legend: {
									display: true,
									position: "bottom",
									align: "center",
									reverse: false,
									labels: {
										usePointStyle: true,
										pointStyle: "rectRounded"
									}
								}
							}}
							options={{
								layout: {
									padding: {
										top: 10,
										bottom: 10
									}
								}
							}}
						/>
					</div>
				) : (
					<div>
						<span className="no-data-found-wrapper">
							<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
							{t("common.global.noDataFound")}
						</span>
					</div>
				)}
			</div>
		</div>
	)
}
export default TotalStatistics
