import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import FilterAccordion from '../FilterAccordion';
import { useTranslation } from 'react-i18next';
import DateRangeFilter from 'components/ui/filters/items/DateRangeFilter';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const ContractStatusFilter = ({
  options,
  label,
  queryName = '',
  disabled = false,
}) => {
  const { t } = useTranslation();
  const [repairTypeValue, setRepairTypeValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const handleRepairTypeChange = (value) => {
    if (value !== 'all') {
      searchParams.set(queryName, value);
      const { label } = options?.filter(({ code }) => code == value)?.[0];
      setRepairTypeValue(label);
    } else {
      searchParams.delete(queryName);
      setRepairTypeValue('Barchasi');
    }
    setSearchParams(searchParams);
  };

  return (
    <FilterAccordion
      title={label || 'Shartnoma holati'}
      code='home-repair-type'
      className='!mb-0.5'
    >
      <FormControl fullWidth color='formColor' type='filterRadioGroup'>
        <RadioGroup
          aria-labelledby='repair-type-radio-buttons-group'
          name='repair-type-radio-buttons-group-name'
          value={searchParams.get(queryName) || 'all'}
          onChange={(event) => handleRepairTypeChange(event.target.value)}
        >
          <div className='mb-1 w-full'>
            <FormControlLabel
              className='w-full'
              value={'all'}
              control={<Radio />}
              label={'Barchasi'}
              type='radio'
              disabled={disabled}
            />
          </div>
          {options?.map((option) => (
            <div className='mb-1 w-full' key={option.code}>
              <FormControlLabel
                className='w-full'
                value={option.code}
                control={<Radio />}
                label={t(option.label)}
                type='radio'
                disabled={disabled}
              />
            </div>
          ))}
          {repairTypeValue === 'custom' ? <DateRangeFilter /> : null}
        </RadioGroup>
      </FormControl>
    </FilterAccordion>
  );
};
export default ContractStatusFilter;
