import React from "react"
import NotificationMenu from "./items/NotificationMenu"
import useTopPanel from "hooks/useTopPanel"
import LanguageMenu from "./items/LanguageMenu"
import DailyCurrency from "./items/DailyCurrency"
import CallsHistory from "./items/calls-history/CallsHistory"

const TopPanel = () => {
	const { component } = useTopPanel()

	return (
		<div className="with-max-width h-16 my-shadow-3">
			<div
				id="top-panel"
				className="top-panel-wrapper py-4 px-6 flex items-center justify-between z-[10] h-16 with-max-width"
			>
				<div className="top-panel-tools flex items-center justify-between w-full mx-2">
					{component}
					<div className="flex items-center ml-auto">
						<div>
							<DailyCurrency />
						</div>
						<div className="ml-2">
							<CallsHistory />
						</div>
						<div className="mx-2">
							<NotificationMenu />
						</div>
						<div>
							<LanguageMenu />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TopPanel
