import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import { t } from 'i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';
import React, { memo, useState } from 'react';
import FormSelectField from 'components/ui/form/FormSelectField';
import FormTextField from 'components/ui/form/FormTextField';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';

const validationSchema = yup.object({
  name: yup.string().min(3).required(),
  type: yup.string().required(),
});

export default memo(function ModalNewFieldMaker({ open, setOpen, refetch }) {
  const handleClose = () => {
    setOpen(false);
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [options, setOptions] = useState([
    { name: '', _id: new Date().toString() },
  ]);
  const sendNotification = useNotification();
  const axiosPrivate = useAxiosPrivate();

  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
    },
    validateOnBlur: validationSchema,
    // onSubmit: async (values) => {
    //   handleSave(values);
    // },
  });

  const handleSave = async () => {
    setIsSubmitting(true);

    const noEmpty = options
      ?.filter(({ name }) => name)
      ?.map(({ name }) => ({ name }));

    const body =
      formik?.values?.type !== 'SELECT'
        ? formik?.values
        : { ...formik?.values, options: noEmpty };
    try {
      const response = await axiosPrivate.post(
        '/crm/crm-settings/store',
        JSON.stringify(body),
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (response && response.data && response.data.status) {
        refetch();
        setOpen(false);
        sendNotification({
          msg: response?.data?.message || response?.message,
          variant: 'success',
        });
      }
    } catch (error) {
      sendNotification({
        msg: error?.response?.data?.message || error?.message,
        variant: 'error',
      });
    }
    setIsSubmitting(false);
  };
  const onRemoveOption = (i) => {
    setOptions(options.filter((_, index) => index !== i));
  };
  const onOptionChange = (e, i) => {
    const { value } = e.target;
    setOptions((opts) =>
      opts?.map((opt, j) => (i === j ? { ...opt, name: value } : opt))
    );
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='alert-dialog-title' className='!pt-1.5'>
        <span className='pr-5 text-base'>
          Maydon va guruh xususiyatlarini sozlash
        </span>
        <div className='close-btn-wrapper'>
          <IconButton variant='onlyIcon' color='primary' onClick={handleClose}>
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {/* <form onSubmit={formik.handleSubmit}> */}
        <div className='min-w-96 w-full'>
          <FormSelectField
            delay={0}
            duration={0}
            fieldName='type'
            label='Turi'
            formik={formik}
            optionValueName='type'
            path={'/crm/crm-settings/types'}
            isItemSizeSmall={true}
          />
        </div>
        <div>
          <FormTextField
            delay={0}
            duration={0}
            fieldName='name'
            label='Nomi'
            formik={formik}
            isItemSizeSmall={true}
          />
        </div>

        {formik.values.type === 'SELECT' && (
          <div className='mt-3'>
            {options.map((option, index) => (
              <div key={option?._id}>
                <div className='text-sm'>{index + 1}-variant</div>
                <div className='flex gap-2'>
                  <TextField
                    delay={0}
                    duration={0}
                    value={option?.[index]?.name}
                    onChange={(e) => onOptionChange(e, index)}
                    fullWidth
                    isItemSizeSmall={true}
                  />
                  <Button
                    size='small'
                    variant='outlined'
                    onClick={() => onRemoveOption(index)}
                    sx={{
                      height: 40,
                      marginTop: 1,
                      minWidth: 40,
                      borderColor: 'red',
                    }}
                  >
                    <i className='bi bi-trash-fill text-red-600' />
                  </Button>
                </div>
              </div>
            ))}
            <div className='item-value mb-2'>
              <Button
                startIcon={<i className='bi bi-plus' />}
                size='small'
                variant='outlined'
                className='!text-gray-400 hover:!text-black'
                sx={{ borderStyle: 'dashed' }}
                type='submit'
                onClick={() =>
                  setOptions([
                    ...options,
                    { name: '', _id: new Date().toString() },
                  ])
                }
              >
                Yangi qo'shish
              </Button>
            </div>
          </div>
        )}
        <div className='mt-3 text-right'>
          <Button
            variant='contained'
            sx={{ borderRadius: '25px' }}
            disabled={isSubmitting}
            onClick={handleSave}
            endIcon={
              isSubmitting ? (
                <CircularProgress size={20} color='info' />
              ) : undefined
            }
          >
            {t('common.button.save')}
          </Button>
        </div>
        {/* </form> */}
      </DialogContent>
    </Dialog>
  );
});
