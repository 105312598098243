import { Button } from '@mui/material';
import CRMBlocksMenu from './CRMBlocksMenu';
import { Link, useNavigate } from 'react-router-dom';
import CRMOperatorSync from './CRMOperatorSync';
import CRMLeadSearch from './card/CRMLeadSearch';
import { useState } from 'react';
import CRMFileUploadModal from './CRMFileUploadModal';
import StaffFilter from 'components/ui/filters/items/StaffFilter';

const CRMTopPanel = ({
  selectedBlock = {},
  setSelectedBlock = () => {},
  addBlock = false,
  addLead = false,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <div className='crm-top-panel-wrapper'>
      <CRMBlocksMenu
        selectedBlock={selectedBlock}
        setSelectedBlock={setSelectedBlock}
        addAllowed={addBlock}
      />
      <div className='flex ml-auto items-center'>
        <Button
          variant='action'
          color='success'
          className='!mr-2 h-10'
          onClick={() => setOpen(!open)}
        >
          <i className='bi bi-upload' />
        </Button>
        <Link
          to={'#'}
          //   to={`${process.env.REACT_APP_BACKEND_URL}/excel/debitors`}
          //   target='_blank'
          className='no-underline'
        >
          <Button variant='action' color='success' className='!mr-2 h-10'>
            <i className='bi bi-download' />
          </Button>
        </Link>
        <div className='mr-3'>
          <Button
            variant='contained'
            sx={{ minWidth: 40 }}
            color='info'
            onClick={() => {
              navigate('/admin/crm/staff-connection');
            }}
            className='h-10'
          >
            <i className='bi bi-link-45deg text-xl' />
          </Button>
        </div>
        <div className='mr-3 lg:w-[200px] w-[150px]'>
          <StaffFilter />
        </div>
        <div className='mr-3'>
          <CRMLeadSearch />
        </div>
        <div className='mr-3'>
          <Button
            variant='contained'
            color='success'
            onClick={() => {
              navigate('/admin/crm/lead/add');
            }}
            className='h-10'
          >
            Yangi yaratish
          </Button>
        </div>
        <div>
          <CRMOperatorSync />
        </div>
      </div>
      {open ? <CRMFileUploadModal open={open} setOpen={setOpen} /> : null}
    </div>
  );
};
export default CRMTopPanel;
