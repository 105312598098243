import { CircularProgress } from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useCallback, useEffect, useRef, useState, memo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useInfiniteQuery } from 'react-query';
import { Link } from 'react-router-dom';
import AcceptAction from './AcceptAction';

const CRMBlockDroppableCards = ({
  leadData = [],
  columnId = '',
  parentDroppableId = '',
  setLeadsData = () => {},
}) => {
  const [enabled, setEnabled] = useState(false);
  const [isLeadDataLoading, setIsLeadDataLoading] = useState(true);
  const axiosPrivate = useAxiosPrivate();
  const intObserver = useRef();

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  const { fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
    useInfiniteQuery(
      `leadData${columnId}`,
      ({ pageParam = 1 }) => getLeadsByPage(pageParam),
      {
        onSuccess: (data) => {
          if (data && data.pages.length > 0) {
            setLeadsData((prevLeads) => {
              let newLeadsData = JSON.parse(JSON.stringify(prevLeads));
              if (newLeadsData.hasOwnProperty(`${columnId}`)) {
                newLeadsData[`${columnId}`] = data.pages.flat(2);
              } else {
                newLeadsData[`${columnId}`] = data.pages.flat(2);
              }
              return newLeadsData;
            });
          }
          setIsLeadDataLoading(false);
        },
        enabled: columnId && columnId > 0 && enabled,
        getNextPageParam: (lastPage, allPages) => {
          return lastPage.length ? allPages.length + 1 : undefined;
        },
      }
    );

  const getLeadsByPage = async (pageParam = 1, options = {}) => {
    const response = await axiosPrivate.get(
      `/crm/crm-lead/${columnId}?page=${pageParam}`
    );
    return response.data?.data?.data;
  };

  const lastLeadRef = useCallback(
    (leadD) => {
      if (isFetchingNextPage) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((leads) => {
        if (leads[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (leadD) intObserver.current.observe(leadD);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  if (!enabled) {
    return null;
  }

  return (
    <div className='crm-block-grid-column-body'>
      {isLeadDataLoading ? (
        <div className='flex items-center justify-center bg-white h-full max-h-[200px] rounded-lg'>
          <CircularProgress size={25} color='inherit' />
        </div>
      ) : (
        <Droppable droppableId={parentDroppableId} type='CARD'>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={`column-cards-wrapper${
                snapshot.isDraggingOver ? ' card-dragging-over-column' : ''
              }${
                Boolean(snapshot.draggingFromThisWith).toString()
                  ? ' card-dragging-from-column'
                  : ''
              }`}
            >
              {leadData && leadData.length > 0
                ? leadData.map((card, index) => (
                    <Draggable
                      draggableId={`card-${card.id}`}
                      index={index}
                      key={`card-${card.id}`}
                    >
                      {(providedCard, snapshotCard) => (
                        <div
                          className='relative group mb-2'
                          ref={providedCard.innerRef}
                          {...providedCard.draggableProps}
                          {...providedCard.dragHandleProps}
                        >
                          <Link
                            to={`/admin/crm/lead/edit/${card.id}`}
                            className={`column-card no-underline${
                              false ? ' scale-110' : ''
                            }`}
                            key={`card-${card.id}`}
                          >
                            {card?.name}
                          </Link>
                          {!card?.user_id && (
                            <AcceptAction id={card?.id} refetch={refetch} />
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))
                : ''}

              {isFetchingNextPage ? (
                <div className='flex items-center justify-center bg-transparent rounded-lg py-2'>
                  <CircularProgress size={20} color='inherit' />
                </div>
              ) : (
                <div
                  className='observable-item h-0.5 opacity-0'
                  id={`observable-item-${columnId}`}
                  ref={lastLeadRef}
                ></div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </div>
  );
};
export default memo(CRMBlockDroppableCards);
