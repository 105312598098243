import React, { memo } from 'react';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const DateRangeFilter = ({ queries = ['from', 'till'] }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const values = [
    searchParams.get(queries?.[0]),
    searchParams.get(queries?.[1]),
  ];
  const onChange = (values) => {
    if (values?.[0]?._isValid && values?.[1]?._isValid) {
      const dateFrom = moment(values?.[0]).format('YYYY-MM-DD');
      const dateTo = moment(values?.[1]).format('YYYY-MM-DD');
      searchParams.set(queries?.[0], dateFrom);
      searchParams.set(queries?.[1], dateTo);
    } else if (values?.[0] === null && values?.[1] === null) {
      searchParams.delete(queries?.[0]);
      searchParams.delete(queries?.[1]);
    }
    setSearchParams(searchParams);
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      localeText={{ start: 'from', end: 'till' }}
    >
      <DateRangePicker
        value={values}
        onChange={onChange}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField {...startProps} />
            <Box sx={{ mx: 0.2 }} />
            <TextField {...endProps} />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
};

export default memo(DateRangeFilter);
