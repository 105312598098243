import {
  Button,
  ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import SimpleNumberField from 'components/ui/simple-fields/numeric-format/SimpleNumberField';
import SimpleRadioGroupField from 'components/ui/simple-fields/radio-group/SimpleRadioGroupField';
import SimpleToggleButtonGroup from 'components/ui/simple-fields/toggle-button-group/SimpleToggleButtonGroup';
import useCurrency from 'hooks/useCurrency';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useReactToPrint } from 'react-to-print';
import CURRENCY_TYPE from 'shared/currencyTypeList';
import REPAIR_TYPE from 'shared/repairTypeList';
import { currencyTypeSelectOptions } from 'shared/selectOptionsList';
import { formatMultiplies, formatSingle } from 'utils/formatOddDecimal';
import ShaxmatkaHomeDetailToPrint from './ShaxmatkaHomeDetailToPrint';
import ImagesSwiper from 'components/ImagesSwiper';
import EditableNumberField from 'components/ui/editable-fields/EditableNumberField';
import HOME_TYPE from 'shared/homeTypeList';
import OrderAddModal from 'pages/admin/order/OrderAddModal';
import usePermission from 'hooks/usePermission';
import { Link } from 'react-router-dom';
import { fromTextToBase64parser } from 'utils/textParser';

const ShaxmatkaMoreHomeDetailsModal = ({
  open,
  setOpen,
  homeData = {},
  object = {},
  setRefetch = () => {},
}) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermission();
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [fullPrice, setFullPrice] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);
  const [startPrice, setStartPrice] = useState(0);
  const [startPricePercentage, setStartPricePercentage] = useState(
    homeData?.start
  );
  const [priceForSquare, setPriceForSquare] = useState(
    homeData?.isrepaired == REPAIR_TYPE.REPAIRED.code
      ? homeData?.repaired
      : homeData?.norepaired
  );
  const [square, setSquare] = useState(homeData.square);
  const [discount, setDiscount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [month, setMonth] = useState(48);
  const [priceForMonth, setPriceForMonth] = useState(0);
  const [currency, setCurrency] = useState(
    homeData?.isvalute || CURRENCY_TYPE.SUM.code
  );
  const [monthSelectOptions, setMonthSelectOptions] = useState([
    {
      code: 24,
      label: ['shaxmatka.moreHomeDetails.monthValue', { value: 24 }],
    },
    {
      code: 36,
      label: ['shaxmatka.moreHomeDetails.monthValue', { value: 36 }],
    },
    {
      code: 48,
      label: ['shaxmatka.moreHomeDetails.monthValue', { value: 48 }],
    },
  ]);
  const { currencyData } = useCurrency();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Reverum_${object?.name}_${homeData?.blocks?.name}_${homeData?.number} uy`,
    pageStyle: '@page { size: auto, margin: 0mm; }',
  });

  const handleChangeMonth = (value) => {
    setMonth(parseInt(value));
    setPriceForMonth(formatSingle((sellPrice - startPrice) / parseInt(value)));
  };

  const handleChangeStartPrice = (value) => {
    setStartPrice(value);
    setStartPricePercentage(
      sellPrice ? formatSingle((value * 100) / sellPrice, 3) : 0
    );
    setPriceForMonth(formatSingle((sellPrice - value) / month));
  };

  const handleCurrencyTypeChange = (value) => {
    setCurrency(value);
    if (currencyData && currencyData.sum) {
      if (value == CURRENCY_TYPE.VALUTE.code) {
        setFullPrice(fullPrice / currencyData.sum);
        setSellPrice(sellPrice / currencyData.sum);
        setDiscount(discount / currencyData.sum);
        setStartPrice(startPrice / currencyData.sum);
        setPriceForMonth(priceForMonth / currencyData.sum);
        setPriceForSquare(priceForSquare / currencyData.sum);
      } else {
        setFullPrice(fullPrice * currencyData.sum);
        setSellPrice(sellPrice * currencyData.sum);
        setDiscount(discount * currencyData.sum);
        setStartPrice(startPrice * currencyData.sum);
        setPriceForMonth(priceForMonth * currencyData.sum);
        setPriceForSquare(priceForSquare * currencyData.sum);
      }
    }
  };

  const handleChangeSellPrice = (newSellPrice) => {
    if (newSellPrice <= fullPrice) {
      setDiscount(fullPrice - newSellPrice);
      setDiscountPercentage(
        fullPrice
          ? formatSingle(((fullPrice - newSellPrice) * 100) / fullPrice, 2)
          : 0
      );
      setSellPrice(newSellPrice);
      setPriceForMonth(formatSingle((newSellPrice - startPrice) / month));
      setPriceForSquare(formatSingle(newSellPrice / square));
    }
  };

  const handleChangePriceSquare = (newPriceForSquare) => {
    let currPriceByRepaired =
      homeData?.isrepaired == REPAIR_TYPE.REPAIRED.code
        ? parseFloat(homeData?.repaired)
        : parseFloat(homeData?.norepaired);

    let maxPrice =
      homeData?.isvalute == CURRENCY_TYPE.SUM.code &&
      currency == CURRENCY_TYPE.VALUTE.code
        ? currPriceByRepaired / currencyData.sum
        : homeData?.isvalute == CURRENCY_TYPE.VALUTE.code &&
          currency == CURRENCY_TYPE.SUM.code
        ? currPriceByRepaired * currencyData.sum
        : currPriceByRepaired;

    if (newPriceForSquare <= maxPrice) {
      let newDiscount = formatMultiplies(
        [maxPrice - newPriceForSquare, homeData.square],
        2
      );
      setDiscount(newDiscount);
      setDiscountPercentage(formatSingle((newDiscount * 100) / fullPrice, 2));
      let newSellP = formatMultiplies([newPriceForSquare, homeData.square], 2);
      setSellPrice(newSellP);
      setPriceForMonth(formatSingle(newSellP - startPrice) / month, 2);
      setPriceForSquare(newPriceForSquare);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let sellP =
      homeData?.isrepaired == REPAIR_TYPE.REPAIRED.code
        ? homeData?.repaired && homeData?.square
          ? formatMultiplies([homeData.repaired, homeData.square], 2)
          : 0
        : homeData?.norepaired && homeData?.square
        ? formatMultiplies([homeData.norepaired, homeData.square], 2)
        : 0;
    setFullPrice(sellP);
    setSellPrice(sellP);
    setPriceForMonth(
      formatSingle(
        (sellP - formatSingle((sellP * +homeData?.start) / 100, 2)) / month
      )
    );
    setStartPrice(formatSingle((sellP * +homeData?.start) / 100, 2));
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='shaxmatka-more-home-details-dialog-title'>
        <div className='close-btn-wrapper'>
          <IconButton variant='onlyIcon' color='primary' onClick={handleClose}>
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        {homeData && Object.keys(homeData).length > 0 && (
          <div className='max-w-[80vw]'>
            <div>
              <div className='flex items-center justify-between'>
                Xonadon ma'lumotlari
                <div className='flex items-center justify-end h-14'>
                  {currency == CURRENCY_TYPE.VALUTE.code && (
                    <div className='mr-2 whitespace-nowrap'>
                      {t('common.fields.currency')}:{' '}
                      <NumericFormat
                        value={currencyData?.sum}
                        allowNegative={false}
                        displayType={'text'}
                        thousandSeparator={' '}
                        decimalScale={1}
                        className='w-full text-right'
                        suffix=' UZS'
                      />
                    </div>
                  )}
                  <SimpleToggleButtonGroup
                    delay={0}
                    duration={0}
                    value={currency?.toString()}
                    changeFn={handleCurrencyTypeChange}
                    options={currencyTypeSelectOptions}
                    size='small'
                    fullWidth={true}
                    buttonClassName='whitespace-nowrap'
                  />
                </div>
              </div>
              <div className='w-[300px] my-shadow-1 -mt-2 rounded-lg p-2'>
                <div className='w-[300px] h-[200px]'>
                  {homeData?.plan &&
                    homeData?.plan?.link &&
                    homeData?.plan?.link2d && (
                      <ImagesSwiper
                        imageURLs={[
                          homeData?.plan?.link,
                          homeData?.plan?.link2d,
                        ]}
                      />
                    )}
                </div>
                <div className='flex items-center justify-between w-full relative mt-1'>
                  <span className='w-full h-[1px] bg-gray-200 absolute'></span>
                  <div className='z-10 bg-white px-1 ml-4'>
                    <ButtonBase className='w-[32px] h-[32px] text-sm p-2 my-shadow-1 rounded-lg flex flex-col !bg-base-color-light !text-white'>
                      {homeData?.rooms}x
                    </ButtonBase>
                  </div>
                  {discountPercentage ? (
                    <div className='z-10 bg-white px-1 mr-4'>
                      <Button color='warning' variant='outlined' size='small'>
                        <i className='bi bi-tag text-md mr-0.5' />{' '}
                        {discountPercentage}%
                      </Button>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className='text-sm mt-1 p-2'>
                  <div>
                    ={object?.name}, {homeData?.blocks?.name}, №
                    {homeData?.number}
                  </div>
                  <div>
                    <EditableNumberField
                      initialValue={sellPrice}
                      changeFn={handleChangeSellPrice}
                      decimalScale={1}
                    >
                      <NumericFormat
                        value={sellPrice}
                        allowNegative={false}
                        displayType={'text'}
                        thousandSeparator={' '}
                        decimalScale={1}
                        className='w-full text-right text-base-color-light'
                        suffix={currency == '1' ? ' $' : ' UZS'}
                      />
                    </EditableNumberField>
                  </div>
                  <div>
                    {homeData?.square}{' '}
                    <Trans i18nKey='common.global.meter'>
                      m<sup>2</sup>
                    </Trans>{' '}
                    •{' '}
                    <EditableNumberField
                      initialValue={priceForSquare}
                      changeFn={handleChangePriceSquare}
                      decimalScale={1}
                    >
                      <NumericFormat
                        value={priceForSquare}
                        allowNegative={false}
                        displayType={'text'}
                        thousandSeparator={' '}
                        decimalScale={1}
                        className='w-full text-right text-base-color-light'
                        suffix={currency == '1' ? ' $' : ' UZS'}
                      />
                    </EditableNumberField>
                  </div>
                </div>

                {discount > 0 && (
                  <Fragment>
                    <div className='w-full relative'>
                      <span className='w-full h-[1px] bg-gray-200 absolute'></span>
                    </div>
                    <div className='text-sm mt-2'>
                      <div>
                        <i className='bi bi-tag text-md mr-0.5 text-base-color-light' />{' '}
                        {discountPercentage}% lik chegirma qo'llanildi
                      </div>
                      <div>
                        <i className='bi bi-patch-check text-md mr-0.5 text-base-color-light' />{' '}
                        <NumericFormat
                          value={discount}
                          allowNegative={false}
                          displayType={'text'}
                          thousandSeparator={' '}
                          decimalScale={1}
                          className='w-full text-right'
                          suffix={currency == '1' ? ' $' : ' UZS'}
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>

            <div className='mt-2'>
              <div className='mb-2'>To'lov shartnomalari</div>
              <Grid
                container
                spacing={2}
                rowSpacing={2}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <Grid item={true} md={3} sm={4} xs={12}>
                  <div className='border border-base-color-light rounded-lg p-3 flex flex-col my-shadow-1'>
                    <div className='border border-base-color-light rounded-full w-10 h-10 flex items-center justify-center mb-2'>
                      <i className='bi bi-currency-dollar text-base-color-light' />
                    </div>
                    <div>
                      <div className='text-gray-500 text-sm'>Umumiy summa</div>
                      <NumericFormat
                        value={fullPrice}
                        allowNegative={false}
                        displayType={'text'}
                        thousandSeparator={' '}
                        decimalScale={1}
                        className='w-full text-right'
                        suffix={currency == '1' ? ' $' : ' UZS'}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item={true} md={3} sm={4} xs={12}>
                  <div className='border border-base-color-light rounded-lg p-3 flex flex-col my-shadow-1'>
                    <div className='border border-base-color-light rounded-full w-10 h-10 flex items-center justify-center mb-2'>
                      <i className='bi bi-currency-dollar text-base-color-light' />
                    </div>
                    <div>
                      <div className='text-gray-500 text-sm'>Chegirma</div>
                      <NumericFormat
                        value={discount}
                        allowNegative={false}
                        displayType={'text'}
                        thousandSeparator={' '}
                        decimalScale={1}
                        className='w-full text-right'
                        suffix={currency == '1' ? ' $' : ' UZS'}
                      />
                      ({discountPercentage}%)
                    </div>
                  </div>
                </Grid>
                <Grid item={true} md={3} sm={4} xs={12}>
                  <div className='border border-base-color-light rounded-lg p-3 flex flex-col my-shadow-1'>
                    <div className='border border-base-color-light rounded-full w-10 h-10 flex items-center justify-center mb-2'>
                      <i className='bi bi-wallet2 text-base-color-light' />
                    </div>
                    <div>
                      <div className='text-gray-500 text-sm'>
                        Boshlang'ich to'lov
                      </div>
                      <NumericFormat
                        value={startPrice}
                        allowNegative={false}
                        displayType={'text'}
                        thousandSeparator={' '}
                        decimalScale={1}
                        className='w-full text-right'
                        suffix={currency == '1' ? ' $' : ' UZS'}
                      />
                      ({startPricePercentage}%)
                    </div>
                  </div>
                </Grid>
                <Grid item={true} md={3} sm={4} xs={12}>
                  <div className='border border-base-color-light rounded-lg p-3 flex flex-col my-shadow-1'>
                    <div className='border border-base-color-light rounded-full w-10 h-10 flex items-center justify-center mb-2'>
                      <i className='bi bi-cash-coin text-base-color-light' />
                    </div>
                    <div>
                      <div className='text-gray-500 text-sm'>
                        Oylik to'lov(
                        {t('shaxmatka.moreHomeDetails.monthValue', {
                          value: month,
                        })}
                        )
                      </div>
                      <NumericFormat
                        value={priceForMonth}
                        allowNegative={false}
                        displayType={'text'}
                        thousandSeparator={' '}
                        decimalScale={1}
                        className='w-full text-right'
                        suffix={currency == '1' ? ' $' : ' UZS'}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className='mt-2'>
              <div className='mb-2'>Ixtiyoriy boshlang'ich to'lov</div>
              <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12 }}>
                <Grid item={true} md={4} sm={6} xs={12}>
                  <div className='rounded-lg my-shadow-1 p-3'>
                    <SimpleNumberField
                      delay={0}
                      duration={0}
                      value={startPrice}
                      setValue={handleChangeStartPrice}
                      label="Boshlang'ich to'lov"
                      name='startPrice'
                    />
                    <div className='flex items-center'>
                      <SimpleRadioGroupField
                        delay={0}
                        duration={0}
                        name='month'
                        label={t('shaxmatka.moreHomeDetails.month')}
                        value={month}
                        changeFn={handleChangeMonth}
                        options={monthSelectOptions}
                        className='flex'
                      />
                      <SimpleNumberField
                        delay={0}
                        duration={0}
                        value={month}
                        setValue={handleChangeMonth}
                        label=''
                        name='monthValue'
                      />
                    </div>
                  </div>
                </Grid>

                <Grid item={true} md={8} sm={6} xs={12}>
                  <TableContainer className='flex-auto w-full'>
                    <Table aria-labelledby='tableTitle'>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <span>Boshlang'ich to'lov</span>
                          </TableCell>
                          <TableCell>
                            <span>Chegirma</span>
                          </TableCell>
                          <TableCell>
                            <span>Sotuv summasi</span>
                          </TableCell>
                          <TableCell>
                            <span>Muddati</span>
                          </TableCell>
                          <TableCell>
                            <span>Oylik to'lov</span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className='overflow-hidden'>
                        <TableRow hover tabIndex={-1}>
                          <TableCell className='!text-[13px]'>
                            <NumericFormat
                              value={startPrice}
                              allowNegative={false}
                              displayType={'text'}
                              thousandSeparator={' '}
                              decimalScale={1}
                              className='w-full text-right'
                              suffix={currency == '1' ? ' $' : ' UZS'}
                            />
                            ({startPricePercentage}%)
                          </TableCell>
                          <TableCell className='!text-[13px]'>
                            <NumericFormat
                              value={discount}
                              allowNegative={false}
                              displayType={'text'}
                              thousandSeparator={' '}
                              decimalScale={1}
                              className='w-full text-right'
                              suffix={currency == '1' ? ' $' : ' UZS'}
                            />
                            ({discountPercentage}%)
                          </TableCell>
                          <TableCell className='!text-[13px]'>
                            <NumericFormat
                              value={sellPrice}
                              allowNegative={false}
                              displayType={'text'}
                              thousandSeparator={' '}
                              decimalScale={1}
                              className='w-full text-right'
                              suffix={currency == '1' ? ' $' : ' UZS'}
                            />
                          </TableCell>
                          <TableCell className='!text-[13px]'>
                            {month}
                          </TableCell>
                          <TableCell className='!text-[13px]'>
                            <NumericFormat
                              value={priceForMonth}
                              allowNegative={false}
                              displayType={'text'}
                              thousandSeparator={' '}
                              decimalScale={1}
                              className='w-full text-right'
                              suffix={currency == '1' ? ' $' : ' UZS'}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>

            <div className='mt-4 mb-2 flex items-center justify-between'>
              <div>
                <div className='hidden'>
                  <ShaxmatkaHomeDetailToPrint
                    ref={componentRef}
                    homeData={{
                      isrepaired:
                        homeData?.isrepaired || REPAIR_TYPE.NOT_REPAIRED.code,
                      repaired: homeData?.repaired,
                      square: square,
                      norepaired: homeData?.norepaired,
                      rooms: homeData?.rooms,
                      isvalute: currency,
                      islive: homeData?.islive,
                      stage: homeData?.stage,
                      number: homeData?.number,
                      status: homeData?.status,
                      plan: homeData?.plan,
                      sellPrice: sellPrice,
                      startPrice: startPrice,
                      startPricePercentage: startPricePercentage,
                      month: month,
                      priceForMonth: priceForMonth,
                      discount: discount,
                      discountPercentage: discountPercentage,
                    }}
                    blockName={homeData?.blocks?.name}
                    objectData={object}
                  />
                </div>
                <Button
                  variant='outlined'
                  color='success'
                  onClick={() => handlePrint()}
                  className='h-9'
                  size='small'
                >
                  <i className='bi bi-printer text-lg mr-1' />
                  Chop etish
                </Button>
              </div>
              <div className='flex items-center'>
                {homeData?.status &&
                  homeData.status == HOME_TYPE.ACTIVE.code &&
                  hasPermission('ORDER_HOMES') && (
                    <Button
                      variant='contained'
                      color='warning'
                      className='h-9'
                      size='small'
                      onClick={() => setOpenOrderModal(true)}
                    >
                      Buyurtma berish
                    </Button>
                  )}

                {homeData?.status &&
                  homeData.status == HOME_TYPE.ACTIVE.code && (
                    <Button
                      variant='contained'
                      color='success'
                      className='h-9 !ml-2 !p-0'
                      size='small'
                    >
                      <Link
                        to={`/admin/contract/add-from-shaxmatka?homeData=${fromTextToBase64parser(
                          JSON.stringify(homeData)
                        )}`}
                        className='no-underline py-1.5 px-3.5 whitespace-nowrap'
                        target='_blank'
                      >
                        Shartnoma tuzish
                      </Link>
                    </Button>
                  )}
              </div>
            </div>
          </div>
        )}
        {openOrderModal && hasPermission('ORDER_HOMES') && (
          <OrderAddModal
            open={openOrderModal}
            setOpen={setOpenOrderModal}
            data={homeData}
            setRefetch={setRefetch}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ShaxmatkaMoreHomeDetailsModal;
