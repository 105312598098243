import { Button, Chip, CircularProgress, Grid } from '@mui/material';
import BackButton from 'components/ui/BackButton';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useTopPanel from 'hooks/useTopPanel';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import PhoneFormat from 'components/ui/text-formats/PhoneFormat';
import { Trans, useTranslation } from 'react-i18next';
import { orderTypeVariants } from 'shared/tableColVariantsList';
import OrderPaymentsTable from './OrderPaymentsTable';
import { NumericFormat } from 'react-number-format';
import { formatMultiplies } from 'utils/formatOddDecimal';
import useNotification from 'hooks/useNotification';
import ORDER_TYPE from 'shared/orderTypeList';

const OrderView = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const { setComponent } = useTopPanel();
  const [isOrderSubmitting, setIsOrderSubmitting] = useState(false);
  const sendNotification = useNotification();
  const navigate = useNavigate();

  const {
    data: orderData,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: 'orderSingle',
    queryFn: async function () {
      const response = await axiosPrivate.get(`/admin/order/edit/${id}`);
      return response.data.data;
    },
    onSuccess: (data) => {},
    enabled: !hasError && !!id,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const handleCancelOrder = async () => {
    try {
      setIsOrderSubmitting(true);
      const response = await axiosPrivate.post(
        '/admin/order/orderdelete',
        JSON.stringify(orderData),
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (response.data && response.data.status) {
        sendNotification({
          msg: t('order.modal.view.alerts.cancel'),
          variant: 'success',
        });
        setIsOrderSubmitting(false);
        refetch();
      }
    } catch (error) {
      sendNotification({
        msg: error?.response?.data?.message || error?.message,
        variant: 'error',
      });
      setIsOrderSubmitting(false);
    }
  };

  const handleAddContract = () => {
    let path = `/admin/contract/add-from-order?fo=true&name=${orderData?.custom?.name}&custom_id=${orderData?.custom?.id}&client_type=${orderData?.custom?.client_type}`;
    if (orderData?.custom?.phone) {
      path += `&phone=${orderData?.custom?.phone}`;
    }
    if (orderData?.custom?.phone2) {
      path += `&phone2=${orderData?.custom?.phone2}`;
    }
    navigate(path, { state: { allowed: true } });
  };

  const setOrderStatus = (item) => {
    let result = orderTypeVariants.filter((variant) => variant.code == item);
    if (result.length > 0) {
      return (
        <Chip
          label={t(result[0].label)}
          variant='tableBadge'
          color={result[0].color}
        />
      );
    }
    return '';
  };

  useEffect(() => {
    setComponent(
      <div className='flex flex-row items-center'>
        <BackButton />
        <div className='component-title flex flex-row'>
          {t('order.view.title')} #{id}
          <span className='ml-2'>
            {!(isLoading || isFetching) &&
              orderData &&
              orderData?.status &&
              setOrderStatus(orderData?.status)}
          </span>
        </div>
      </div>
    );
  }, [orderData, i18n.language]);

  return (
    <div className='component-add-edit-wrapper mx-4'>
      {isLoading || isFetching ? (
        <div className='circular-progress-box py-5'>
          <CircularProgress size={35} />
        </div>
      ) : (
        orderData && (
          <Fragment>
            <div className='component-add-edit-body order-view-wrapper'>
              <div className='py-3 flex flex-row'>
                <div className='base-data w-1/2'>
                  <div className='home-data'>
                    <Grid
                      container
                      spacing={2}
                      columns={{ xs: 12, sm: 12, lg: 12 }}
                    >
                      <Grid item={true} sm={12} xs={12}>
                        {t('order.view.clientDetails')}
                      </Grid>
                      <Grid item={true} sm={6} xs={12}>
                        <div className='home-data-item'>
                          <span className='data-item-title'>
                            {t('order.view.custom')}:
                          </span>
                          <span className='data-item-value underline'>
                            {orderData?.custom?.surname}{' '}
                            {orderData?.custom?.name}{' '}
                            {orderData?.custom?.middlename}
                          </span>
                        </div>
                      </Grid>
                      <Grid item={true} sm={6} xs={12}>
                        <div className='home-data-item'>
                          <span className='data-item-title'>
                            {t('order.view.staff')}:
                          </span>
                          <span className='data-item-value'>
                            {orderData?.staff?.name}
                          </span>
                        </div>
                      </Grid>
                      <Grid item={true} sm={6} xs={12}>
                        <div className='home-data-item'>
                          <span className='data-item-title'>
                            {t('order.view.phone')}:
                          </span>
                          <span className='data-item-value flex flex-col'>
                            <PhoneFormat value={orderData?.custom?.phone} />
                            <PhoneFormat value={orderData?.custom?.phone2} />
                          </span>
                        </div>
                      </Grid>
                      <Grid item={true} sm={12} xs={12}>
                        {t('order.view.homeDetails')}
                      </Grid>
                      <Grid item={true} sm={6} xs={12}>
                        <div className='home-data-item'>
                          <span className='data-item-title'>
                            {t('order.view.objectName')}:
                          </span>
                          <span className='data-item-value'>
                            {orderData?.home?.blocks?.objects?.name || '—'}
                          </span>
                        </div>
                      </Grid>
                      <Grid item={true} sm={6} xs={12}>
                        <div className='home-data-item'>
                          <span className='data-item-title'>
                            {t('order.view.blockName')}:
                          </span>
                          <span className='data-item-value'>
                            {orderData?.home?.blocks?.name || '—'}
                          </span>
                        </div>
                      </Grid>
                      <Grid item={true} sm={6} xs={12}>
                        <div className='home-data-item'>
                          <span className='data-item-title'>
                            {t('order.view.homeNumber')}:
                          </span>
                          <span className='data-item-value'>
                            {orderData?.home?.number || '—'}
                          </span>
                        </div>
                      </Grid>
                      <Grid item={true} sm={6} xs={12}>
                        <div className='home-data-item'>
                          <span className='data-item-title'>
                            {t('order.view.stage')}:
                          </span>
                          <span className='data-item-value'>
                            {orderData?.home?.stage || '—'}
                          </span>
                        </div>
                      </Grid>
                      <Grid item={true} sm={6} xs={12}>
                        <div className='home-data-item'>
                          <span className='data-item-title'>
                            {t('order.view.rooms')}:
                          </span>
                          <span className='data-item-value'>
                            {orderData?.home?.rooms || '—'}
                          </span>
                        </div>
                      </Grid>
                      <Grid item={true} sm={6} xs={12}>
                        <div className='home-data-item'>
                          <span className='data-item-title'>
                            {t('order.view.area')}:
                          </span>
                          <span className='data-item-value'>
                            {orderData?.home?.square ? (
                              <>
                                {orderData?.home?.square}{' '}
                                <Trans i18nKey='common.global.meter'>
                                  m<sup>2</sup>
                                </Trans>
                              </>
                            ) : (
                              '-'
                            )}
                          </span>
                        </div>
                      </Grid>
                      <Grid item={true} sm={6} xs={12}>
                        <div className='home-data-item'>
                          <span className='data-item-title'>
                            {t('order.view.repairedSum')}:
                          </span>
                          <span className='data-item-value'>
                            <NumericFormat
                              value={
                                orderData?.home?.repaired &&
                                orderData?.home?.square
                                  ? formatMultiplies(
                                      [
                                        orderData?.home?.repaired,
                                        orderData?.home?.square,
                                      ],
                                      2
                                    )
                                  : 0
                              }
                              allowNegative={false}
                              displayType={'text'}
                              thousandSeparator={' '}
                              decimalScale={1}
                              className='w-full text-center'
                              suffix={
                                orderData?.home?.isvalute == '1' ? ' $' : ' UZS'
                              }
                            />
                          </span>
                        </div>
                      </Grid>
                      <Grid item={true} sm={6} xs={12}>
                        <div className='home-data-item'>
                          <span className='data-item-title'>
                            {t('order.view.norepairedSum')}:
                          </span>
                          <span className='data-item-value'>
                            <NumericFormat
                              value={
                                orderData?.home?.norepaired &&
                                orderData?.home?.square
                                  ? formatMultiplies(
                                      [
                                        orderData?.home?.norepaired,
                                        orderData?.home?.square,
                                      ],
                                      2
                                    )
                                  : 0
                              }
                              allowNegative={false}
                              displayType={'text'}
                              thousandSeparator={' '}
                              decimalScale={1}
                              className='w-full text-center'
                              suffix={
                                orderData?.home?.isvalute == '1' ? ' $' : ' UZS'
                              }
                            />
                          </span>
                        </div>
                      </Grid>
                      <Grid item={true} sm={12} xs={12}>
                        {t('order.view.orderDetails')}
                      </Grid>
                      <Grid item={true} sm={6} xs={12}>
                        <div className='home-data-item'>
                          <span className='data-item-title'>
                            {t('order.view.leftDate')}:
                          </span>
                          <span className='data-item-value'>
                            {orderData?.date &&
                              moment(orderData?.date, 'YYYY-MM-DD') >
                                moment(new Date()) &&
                              moment(orderData?.date, 'YYYY-MM-DD').diff(
                                new Date(),
                                'days'
                              )}
                          </span>
                        </div>
                      </Grid>
                      <Grid item={true} sm={6} xs={12}>
                        <div className='home-data-item'>
                          <span className='data-item-title'>
                            {t('order.view.date')}:
                          </span>
                          <span className='data-item-value'>
                            {moment(orderData?.date, 'YYYY-MM-DD').format(
                              'DD.MM.YYYY'
                            )}
                          </span>
                        </div>
                      </Grid>
                      <Grid item={true} sm={6} xs={12}>
                        <div className='home-data-item'>
                          <span className='data-item-title'>
                            {t('order.view.paymentStatus')}:
                          </span>
                          <span className='data-item-value'>
                            {t('order.view.noRefund')}
                          </span>
                        </div>
                      </Grid>
                      <Grid item={true} sm={6} xs={12}>
                        <div className='home-data-item'>
                          <span className='data-item-title'>
                            {t('order.view.dealPrice')}:
                          </span>
                          <span className='data-item-value'>
                            <NumericFormat
                              value={
                                orderData?.home?.deal_price &&
                                orderData?.home?.square
                                  ? formatMultiplies(
                                      [
                                        orderData?.home?.deal_price,
                                        orderData?.home?.square,
                                      ],
                                      2
                                    )
                                  : 0
                              }
                              allowNegative={false}
                              displayType={'text'}
                              thousandSeparator={' '}
                              decimalScale={1}
                              className='w-full text-center'
                              suffix={
                                orderData?.home?.isvalute == '1' ? ' $' : ' UZS'
                              }
                            />
                          </span>
                        </div>
                      </Grid>
                      <Grid item={true} sm={12} xs={12}>
                        <Button
                          variant='contained'
                          color='success'
                          disabled={
                            orderData?.status &&
                            (orderData?.status == ORDER_TYPE.ORDERED.code ||
                              orderData?.status == ORDER_TYPE.CANCELLED.code)
                          }
                          className='!mr-3'
                          onClick={() => handleAddContract()}
                        >
                          {t('order.view.createContract')}
                        </Button>
                        <Button
                          variant='contained'
                          color='error'
                          disabled={
                            isOrderSubmitting ||
                            (orderData?.status &&
                              orderData?.status !== ORDER_TYPE.NEW.code)
                          }
                          onClick={() => handleCancelOrder()}
                        >
                          {isOrderSubmitting && (
                            <CircularProgress
                              size={15}
                              color='inherit'
                              className='mr-1'
                            />
                          )}
                          {t('common.button.cancel')}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className='w-1/2 ml-6'>
                  <OrderPaymentsTable
                    paymentsList={orderData?.payments}
                    refetchFn={refetch}
                    orderStatus={orderData?.status}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        )
      )}
    </div>
  );
};

export default OrderView;
