import { Button } from '@mui/material';
import BaseTooltip from 'components/ui/tooltips/BaseTooltip';
import { Fragment, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import HOME_TYPE from 'shared/homeTypeList';
import getLabelByTypeList from 'utils/getLabelByTypeList';

const HomesView = ({
  stagesData = {},
  data = {},
  backFn = () => {},
  selectedBlockId = '',
  selectedStage = '',
  onClickPolygonFn = () => {},
}) => {
  const { t } = useTranslation();
  const homeNumbersWrapperRef = useRef(null);

  const getPolygonPoints = (coordinates) => {
    return JSON.parse(coordinates)
      .flat(2)
      .map((item) => (item * 72).toFixed(3))
      .join();
  };

  const getTextCoordinatesByPolygon = (homeId, type) => {
    let b = document.getElementById(
      `block-${selectedBlockId}-stage-${selectedStage}-home-${homeId}-polygon`
    );
    if (b && homeNumbersWrapperRef.current) {
      let parentWrapDetails =
        homeNumbersWrapperRef.current.getBoundingClientRect();
      if (type == 'x') {
        let polygonData = b.getBoundingClientRect();
        return (
          polygonData?.left + polygonData?.width / 2 - parentWrapDetails?.left
        );
      } else if (type == 'y') {
        let polygonData = b.getBoundingClientRect();
        return (
          polygonData?.bottom - polygonData?.height / 2 - parentWrapDetails?.top
        );
      }
    }
    return 0;
  };

  const resizeFn = () => {
    homeNumbersWrapperRef.current.innerHTML = '';
    if (data.homes && data.homes.length > 0) {
      data.homes.forEach((home) => {
        let childItem = document.createElement('div');
        childItem.innerHTML = `<div class='home-number'>№${
          home?.homes?.number
        }</div><div class='home-status-name'>${getLabelByTypeList(
          HOME_TYPE,
          home?.homes?.status
        )}</div>`;
        childItem.className = `home-number-item status-${home?.homes?.status}`;
        childItem.id = `home-${home?.homes?.id}-number-item`;
        let xAxis = getTextCoordinatesByPolygon(home?.homes?.id, 'x');
        let yAxis = getTextCoordinatesByPolygon(home?.homes?.id, 'y');
        childItem.style.setProperty('--xAxis', `${xAxis}px`);
        childItem.style.setProperty('--yAxis', `${yAxis}px`);
        // childItem.style.transform = `translate(${xAxis}px, ${yAxis}px)`
        // childItem.setAttribute("x", xAxis)
        // childItem.setAttribute("y", yAxis)
        homeNumbersWrapperRef.current.append(childItem);
      });
    }
  };

  const handleMouseEnter = (homeId) => {
    let foundHomeItem = document.getElementById(`home-${homeId}-number-item`);
    if (foundHomeItem) {
      foundHomeItem.classList.add('is-hovered');
      // foundHomeItem.style.setProperty('--xAxis', foundHomeItem.getAttribute(
      // 	"x"
      // ))
      // foundHomeItem.style.transform = `translate(${foundHomeItem.getAttribute(
      // 	"x"
      // )}px, ${foundHomeItem.getAttribute("y")}px) scale(1.2)`
    }
  };

  const handleMouseLeave = (homeId) => {
    let foundHomeItem = document.getElementById(`home-${homeId}-number-item`);
    if (foundHomeItem) {
      foundHomeItem.classList.remove('is-hovered');
      // foundHomeItem.style.transform = `translate(${foundHomeItem.getAttribute(
      // 	"x"
      // )}px, ${foundHomeItem.getAttribute("y")}px)`
    }
  };

  // ! NOTE: don't remove code below.
  // ? 1st APPROACH

  // useEffect(() => {
  // 	if (homeNumbersWrapperRef && homeNumbersWrapperRef.current) {
  // 		window.addEventListener("resize", resizeFn)
  // 	}
  // 	return () => {
  // 		if (window) {
  // 			window.removeEventListener("resize", resizeFn)
  // 		}
  // 	}
  // }, [homeNumbersWrapperRef])

  useEffect(() => {
    let resizeOb = new ResizeObserver(resizeFn);
    resizeOb.observe(homeNumbersWrapperRef.current || '');
    return () => {
      resizeOb.disconnect();
    };
  }, [homeNumbersWrapperRef]);

  return (
    <div className='shaxmatka-visual-view-wrapper'>
      <div className='visual-view-header'>
        <div className='visual-view-back-btn-wrapper'>
          <BaseTooltip title={t('common.button.back')} arrow placement='bottom'>
            <Button variant='action' color='primary' onClick={() => backFn()}>
              <i className='bi bi-arrow-left flex items-center justify-center' />
            </Button>
          </BaseTooltip>
        </div>
        {stagesData.blocks && stagesData.blocks.length > 0 && (
          <div className='visual-view-title'>
            {stagesData.blocks[0]?.block} | {selectedStage}-qavat
          </div>
        )}
      </div>

      <div className='visual-view-body'>
        <img
          src={`${process.env.REACT_APP_BACKEND_URL}/${data?.image}`}
          alt=''
          className='helper-image'
        />
        <img
          src={`${process.env.REACT_APP_BACKEND_URL}/${data?.image}`}
          alt=''
          className='main-image'
          id='main-image'
        />
        <svg
          viewBox={`0 0 ${data?.width} ${data?.height}`}
          xmlns='http://www.w3.org/2000/svg'
          className='svg-item home-svg-polygons'
          id='home-svg-polygons'
        >
          {data.homes &&
            data.homes.length > 0 &&
            data.homes.map((home, index) => (
              <Fragment key={`home-polygon-${index}`}>
                <polygon
                  points={getPolygonPoints(home?.cordinates)}
                  fillOpacity='0.5'
                  style={{
                    transform: `scaleY(-1) translateY(-${+data?.height}px)`,
                  }}
                  className={`home-polygon status-${home?.homes?.status}`}
                  id={`block-${selectedBlockId}-stage-${selectedStage}-home-${home?.homes?.id}-polygon`}
                  onClick={() =>
                    onClickPolygonFn(home?.homes?.id, selectedBlockId)
                  }
                  home-id={home?.homes?.id}
                  onMouseEnter={() => handleMouseEnter(home?.homes?.id)}
                  onMouseLeave={() => handleMouseLeave(home?.homes?.id)}
                />
              </Fragment>
            ))}
        </svg>
      </div>

      <div
        className='visual-view-home-numbers-wrapper'
        ref={homeNumbersWrapperRef}
      >
        {/* 
        // ! NOTE: don't remove code below
        // ? 1st APPROACH

        {data.homes &&
					data.homes.length > 0 &&
					data.homes.map((home, index) => (
						<Fragment key={`home-number-span-${index}`}>
							<div
								style={{
									transform: `translate(${getTextCoordinatesByPolygon(
										home?.homes?.id,
										"x"
									)}px, ${getTextCoordinatesByPolygon(
										home?.homes?.id,
										"y"
									)}px)`
								}}
								className="home-number-item"
							>
								№{home?.homes?.number}
							</div>
						</Fragment>
					))} */}
      </div>
    </div>
  );
};
export default HomesView;
