import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  CircularProgress,
} from '@mui/material';
import { memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';
import example from 'templates/crm-lead-upload-template.xlsx';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const CRMFileUploadModal = (props) => {
  const { open, setOpen } = props;
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const sendNotification = useNotification();

  const handleSave = async () => {
    setIsSubmitting(true);
    try {
      const response = await axiosPrivate.post(
        '/crm/customers/upload',
        JSON.stringify({
          data: data,
          _method: 'put',
        }),
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (response && response.data && response.data.status) {
        setOpen(false);
      }
    } catch (error) {
      sendNotification({
        msg: error?.response?.data?.message || error?.message,
        variant: 'error',
      });
    }
    setIsSubmitting(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClear = () => {
    setFile(null);
    setData([]);
    if (inputRef.current) {
      inputRef.current.type = 'text';
      inputRef.current.type = 'file';
    }
  };

  const handleDownload = () => {
    const a = document.createElement('a');
    a.href = example;
    a.download = 'crm_template.xlsx';
    a.click();
    a.remove();
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    setFile(file);
    try {
      reader.onload = (event) => {
        const workbook = XLSX.read(event.target.result, { type: 'binary' });
        const sheetName = workbook?.SheetNames?.[0];
        const sheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(sheet);
        const formattedData = sheetData.map((data) => {
          const phones = data?.['Telefon raqam']?.split(',');
          
          return {
            name: data?.['Bitim nomi'] || '',
            budget: data?.["Bitim mablag'i"] || '',
            staff: data?.['Bitim uchun javobgar'] || '',
            pipline: data?.['Pipeline'] || '',
            created_day: data?.['Yaratilgan sana (shartnoma)'] || '',
            total_name: data?.["To'liq ism (contact)"] || '',
            status: data?.['Bitim holati'] || '',
            phone: data?.['Telefon raqam'] || '',
          };
        });

        setData(formattedData);
        setHasError(false);
      };
    } catch {
      sendNotification({
        msg: 'Error while reading the file. Please ensure the file is in the correct format.',
        variant: 'error',
      });
      setHasError(true);
    }

    reader.readAsBinaryString(file);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='alert-dialog-title' className='!pt-1.5'>
        <span className='pr-5'>Import</span>
        <div className='close-btn-wrapper'>
          <IconButton variant='onlyIcon' color='primary' onClick={handleClose}>
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <div>
          <div className='max-w-md text-sm'>
            {t('crm.block.fileUploadModal.title')}
          </div>
          <div className='flex gap-2 mt-2'>
            <Button
              variant='outlined'
              component='label'
              role={undefined}
              size='small'
              color='success'
              tabIndex={-1}
              startIcon={<i className='bi bi-file-earmark-arrow-up' />}
            >
              {t('common.fields.uploadFile')}
              <VisuallyHiddenInput
                onChange={handleFileUpload}
                ref={inputRef}
                type='file'
                accept={'.xls, .xlsx, .csv'}
              />
            </Button>
            <Button
              variant='outlined'
              size='small'
              color='info'
              sx={{ borderColor: 'gray', color: '#111' }}
              startIcon={<i className='bi bi-file-earmark-arrow-down' />}
              onClick={handleDownload}
            >
              {t('common.fields.downloadExample')}
            </Button>
          </div>
          {file && (
            <div className='mt-2 text-sm text-green-500 flex gap-1 items-center'>
              <div>
                {t('xlsxUploader.fileName')}: {file.name}
              </div>
              <IconButton
                variant='onlyIcon'
                color='error'
                size='small'
                onClick={handleClear}
              >
                <i className='bi bi-x' />
              </IconButton>
            </div>
          )}
          <div className='mt-3 text-right'>
            <Button
              variant='contained'
              sx={{ borderRadius: '25px' }}
              disabled={!file || hasError || isSubmitting}
              onClick={handleSave}
              endIcon={
                isSubmitting ? (
                  <CircularProgress size={20} color='info' />
                ) : undefined
              }
            >
              {t('common.button.save')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(CRMFileUploadModal);
